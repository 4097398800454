import React, { FunctionComponent, useEffect, useState, useRef, FormEvent } from 'react';
import AuthService from '../Login/AuthService';
import withContext from '../ContextAPI/Context_HOC';
import { withRouter, useLocation } from 'react-router-dom';
import { parse as parseQueryString } from 'query-string';

const urlPartsPattern = /^(?<scheme>https?:\/\/)(?<domain>[^/]+)(?<path>\/.+)$/;

/**
 * Insert port number into URL
 * @param {string} url Like http://localhost/community/auth
 * @param {string} port Like 3000
 * @returns {string} Like http://localhost:3000/community/auth
 */
function insertPortNumber(url: string, port: string): string {
  const urlParts = urlPartsPattern.exec(url);
  if (!urlParts?.groups) {
    throw `USBNC: Unparseable url '${url}'`;
  }

  const { scheme, domain, path } = urlParts.groups;

  return `${scheme}${domain}:${port}${path}`;
}

const localhostWithNumberPattern = /^(?<domain>.+localhost)(?<port>\d+)$/;

function GetUrlFor(site: string): { siteUrl: string; convertQueryToForm: boolean } {
  const siteLowerCaseBeforeLocalhostCheck = site?.toLowerCase();
  const localhostWithNumber = localhostWithNumberPattern.exec(siteLowerCaseBeforeLocalhostCheck);
  const siteLowerCase = localhostWithNumber?.groups ? localhostWithNumber.groups['domain'] : siteLowerCaseBeforeLocalhostCheck;
  const port = localhostWithNumber?.groups ? localhostWithNumber.groups['port'] : '';
  switch (siteLowerCase) {
    case 'unityweb': {
      const siteUrl = process.env.REACT_APP_UNITY_WEB_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'emembership': {
      const siteUrl = process.env.REACT_APP_EMEMBERSHIP_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs': {
      const siteUrl = process.env.REACT_APP_OBS_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-sprint': {
      const siteUrl = process.env.REACT_APP_OBS_SPRINT_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-nbuild': {
      const siteUrl = process.env.REACT_APP_OBS_NBUILD_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'oas-test': {
      const siteUrl = process.env.REACT_APP_LSA_AUDIT_3_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-test': {
      const siteUrl = process.env.REACT_APP_OBS_TEST_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-testingdev-dev1': {
      const siteUrl = process.env.REACT_APP_OBS_TESTINGDEV_DEV1_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-testingdev-dev2': {
      const siteUrl = process.env.REACT_APP_OBS_TESTINGDEV_DEV2_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-testingdev-dev3': {
      const siteUrl = process.env.REACT_APP_OBS_TESTINGDEV_DEV3_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-testingdev-sprint': {
      const siteUrl = process.env.REACT_APP_OBS_TESTINGDEV_SPRINT_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-testingdev-build1': {
      const siteUrl = process.env.REACT_APP_OBS_TESTINGDEV_BUILD1_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-testingdev-build2': {
      const siteUrl = process.env.REACT_APP_OBS_TESTINGDEV_BUILD2_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-api': {
      const siteUrl = process.env.REACT_APP_OBS_API_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obs-localhost': {
      const siteUrlBeforePortCheck = process.env.REACT_APP_OBS_LOCALHOST_AUTH_URL;
      if (!siteUrlBeforePortCheck) {
        throw new Error(`Env not set for site ${site}`);
      }
      const siteUrl = port ? insertPortNumber(siteUrlBeforePortCheck, port) : siteUrlBeforePortCheck;
      return { siteUrl, convertQueryToForm: false };
    }
    case 'obstraining': {
      const siteUrlBeforePortCheck = process.env.REACT_APP_OBS_TRAINING_AUTH_URL;
      if (!siteUrlBeforePortCheck) {
        throw new Error(`Env not set for site ${site}`);
      }
      const siteUrl = port ? insertPortNumber(siteUrlBeforePortCheck, port) : siteUrlBeforePortCheck;
      return { siteUrl, convertQueryToForm: false };
    }
    case 'samlloginget': {
      // There is also a 'samlLoginPost' URL configured in the Nginx configuration
      const siteUrl = process.env.REACT_APP_SAML_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: true };
    }
    case 'lsa-audit': {
      const siteUrl = process.env.REACT_APP_LSA_AUDIT_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'lsa-audit-2': {
      const siteUrl = process.env.REACT_APP_LSA_AUDIT_2_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'ocs': {
      const siteUrl = process.env.REACT_APP_OCS_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'ocstest_ip': {
      const siteUrl = process.env.REACT_APP_OCS_TEST_IP_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'ocstest': {
      const siteUrl = process.env.REACT_APP_OCS_TEST_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'ocstest2_ip': {
      const siteUrl = process.env.REACT_APP_OCS_TEST2_IP_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'ocstest2': {
      const siteUrl = process.env.REACT_APP_OCS_TEST2_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'huqtest_ip': {
      const siteUrl = process.env.REACT_APP_OCS_HPS_TEST_IP_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'huqtest': {
      const siteUrl = process.env.REACT_APP_OCS_HPS_TEST_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'huqtest2_ip': {
      const siteUrl = process.env.REACT_APP_OCS_HPS_TEST2_IP_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    case 'huqtest2': {
      const siteUrl = process.env.REACT_APP_OCS_HPS_TEST2_AUTH_URL;
      if (!siteUrl) {
        throw new Error(`Env not set for site ${site}`);
      }
      return { siteUrl, convertQueryToForm: false };
    }
    default:
      throw new Error(`Site ${site} is not implemented`);
  }
}

function BuildInputValues(queryString: string): { inputName: string; inputValue: string }[] {
  var queryParsedIntoObject = parseQueryString(queryString);
  return Object.entries(queryParsedIntoObject).reduce((accumulator, entry) => {
    const inputName = entry[0];
    const inputValue = entry[1]?.toString() ?? '';
    accumulator.push({ inputName, inputValue });
    return accumulator;
  }, [] as { inputName: string; inputValue: string }[]);
}

const SingleSignOnRedirect: FunctionComponent<{
  match: { params: { site: string } };
  context: { authService: AuthService };
  testHelperOnSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined;
}> = ({ match, context, testHelperOnSubmit }) => {
  const submitButtonRef = useRef<HTMLInputElement>(null);
  const authService = context.authService;
  const jwt = authService.getToken();
  if (!jwt) {
    throw new Error('JWT not available');
  }

  const { siteUrl, convertQueryToForm } = GetUrlFor(match?.params?.site);
  const queryString = useLocation().search;
  const redirectUrlWithQuery = convertQueryToForm ? siteUrl : `${siteUrl}${queryString}`;

  const additionaInputValues: { inputName: string; inputValue: string }[] = convertQueryToForm
    ? BuildInputValues(queryString)
    : [];

  const [displayMessage, setDisplayMessage] = useState('Preparing...');

  useEffect(() => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
      setDisplayMessage('Redirecting...');
    }
  }, []);

  return (
    <div>
      <p>{displayMessage}</p>
      <form data-cy="redirect-form" method="post" action={redirectUrlWithQuery} onSubmit={testHelperOnSubmit}>
        <input readOnly hidden={true} value={jwt} name="tkn" />
        {additionaInputValues.map((x, i) => (
          <input readOnly hidden={true} value={x.inputValue} name={x.inputName} key={i} data-cy={x.inputName}></input>
        ))}

        <input ref={submitButtonRef} hidden={true} type="submit" name="submit" />
      </form>
    </div>
  );
};

export default withRouter(withContext(SingleSignOnRedirect));
